/**
 * @typedef {import("@/types/ht/SampleState.d").Address} Address
 */

// utility functions for housings
// Color models constants
const RGB_MAX = 255;
const HUE_MAX = 360;
const SV_MAX = 100;

// if we have two housings with same dimensions labeled differently,
// we can convert addresses from one to another; say one has numbers and
// another has letters
function translate_address(address, dim_from, dim_to) {
  const trimmed_addr = { ...address };
  delete trimmed_addr.sub_housing_ind;
  const r = {};
  for (const [k, v] of Object.entries(trimmed_addr)) {
    const index = dim_from[0][k].indexOf(v);
    r[k] = dim_to[0][k][index];
  }
  return r;
}

function hex_to_rgb(hex) {
  // Manage shorthand hexadecimal form
  const shorthand = /^#?([\da-f])([\da-f])([\da-f])$/i.exec(hex);
  if (shorthand) {
    return {
      r: Number.parseInt(`${shorthand[1]}${shorthand[1]}`, 16),
      g: Number.parseInt(`${shorthand[2]}${shorthand[2]}`, 16),
      b: Number.parseInt(`${shorthand[3]}${shorthand[3]}`, 16),
    };
  }
  // Manage hexadecimal form
  const full = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
  if (full) {
    return {
      r: Number.parseInt(full[1], 16),
      g: Number.parseInt(full[2], 16),
      b: Number.parseInt(full[3], 16),
    };
  }
  return null;
}

function rgb_to_hsv(r, g, b) {
  let norm_r, norm_g, norm_b;

  if (typeof r === "object") {
    const { r: r_o, g: g_o, b: b_o } = r;
    [norm_r, norm_g, norm_b] = [r_o, g_o, b_o];
  }

  // It converts [0,255] format, to [0,1]
  norm_r = (r === RGB_MAX) ? 1 : ((r % RGB_MAX) / Number.parseFloat(RGB_MAX));
  norm_g = (g === RGB_MAX) ? 1 : ((g % RGB_MAX) / Number.parseFloat(RGB_MAX));
  norm_b = (b === RGB_MAX) ? 1 : ((b % RGB_MAX) / Number.parseFloat(RGB_MAX));

  const max = Math.max(norm_r, norm_g, norm_b);
  const min = Math.min(norm_r, norm_g, norm_b);
  let h; const v = max;

  const d = max - min;
  const s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case norm_r:
        h = (norm_g - norm_b) / d + (norm_g < norm_b ? 6 : 0);
        break;
      case norm_g:
        h = (norm_b - norm_r) / d + 2;
        break;
      case norm_b:
        h = (norm_r - norm_g) / d + 4;
        break;
      default:
        throw new Error("Unexpected error");
    }
    h /= 6;
  }

  return {
    h: Math.round(h * HUE_MAX),
    s: Math.round(s * SV_MAX),
    v: Math.round(v * SV_MAX),
  };
}
function hsv_to_rgb(h, s, v) {
  if (typeof h === "object") {
    const args = h;
    h = args.h; s = args.s; v = args.v; // eslint-disable-line no-param-reassign
  }

  h = ((h % 360) + 360) % 360; // eslint-disable-line no-param-reassign
  h = (h === HUE_MAX) ? 1 : (((h % HUE_MAX) / Number.parseFloat(HUE_MAX)) * 6); // eslint-disable-line no-param-reassign
  s = (s === SV_MAX) ? 1 : ((s % SV_MAX) / Number.parseFloat(SV_MAX)); // eslint-disable-line no-param-reassign
  v = (v === SV_MAX) ? 1 : ((v % SV_MAX) / Number.parseFloat(SV_MAX)); // eslint-disable-line no-param-reassign

  const i = Math.floor(h);
  const f = h - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  const mod = i % 6;
  const r = [v, q, p, p, t, v][mod];
  const g = [t, v, v, q, p, p][mod];
  const b = [p, p, t, v, v, q][mod];

  return {
    r: Math.floor(r * RGB_MAX),
    g: Math.floor(g * RGB_MAX),
    b: Math.floor(b * RGB_MAX),
  };
}

function rgb_to_hex(r, g, b) {
  let norm_r, norm_g, norm_b;
  if (typeof r === "object") {
    const { r: r_o, g: g_o, b: b_o } = r;
    [norm_r, norm_g, norm_b] = [r_o, g_o, b_o];
  }

  const hex_to_str = (num) => Math.round(num).toString(16);
  [norm_r, norm_g, norm_b] = [hex_to_str(r), hex_to_str(g), hex_to_str(b)];

  norm_r = norm_r.length === 1 ? `0${norm_r}` : norm_r;
  norm_g = norm_g.length === 1 ? `0${norm_g}` : norm_g;
  norm_b = norm_b.length === 1 ? `0${norm_b}` : norm_b;

  return `#${norm_r}${norm_g}${norm_b}`;
}
function hex_to_hsv(hex) {
  const { r, g, b } = hex_to_rgb(hex);
  return rgb_to_hsv(r, g, b);
}

function hsv_to_hex(h, s, v) {
  const { r, g, b } = hsv_to_rgb(h, s, v);
  return rgb_to_hex(r, g, b);
}

/**
 * Returns an 2d-array of gen_op arguments for multiple operations.
 * Generated addresses for sample_states_out are kept the same as they were in hs_in.
 * (Identical dimension housings required)
 *
 * @param {Array<Object>} sample_states - sample states being mapped automatically
 * @param {Array<Object>} available_hss_out - housing states into which the sample states are being mapped
 * @param {Boolean=} preserve_addresses - false by default; ss_out retain their initial addresses
 * @returns {([SampleState, number, Address])[]} array of sample states each with its corresponding housing state id and new address
 */
function assign_addresses(sample_states, available_hss_out, preserve_addresses = false) {
  const get_dimensions = (h) => h.housing.housing_type.dimensions[0];
  const { x, y = null } = get_dimensions(available_hss_out[0]);
  let hs_num = 0;
  const gen_op_params_2d = [];

  let x_ctr = 0;
  let y_ctr = 0;
  for (const sample_state of sample_states) {
    const dst_address = preserve_addresses ? { ...sample_state.address } : { x: x[x_ctr], y: y[y_ctr] };
    gen_op_params_2d.push([sample_state, { [available_hss_out[hs_num].id]: [dst_address] }]);
    if (y_ctr === y.length - 1 && x_ctr === x.length - 1) {
      x_ctr = 0;
      y_ctr = 0;
      hs_num += 1;
      if (hs_num > available_hss_out.length - 1) break;
      continue;
    }
    if (y_ctr === y.length - 1) {
      x_ctr += 1;
      y_ctr = 0;
    } else y_ctr += 1;
  }

  return gen_op_params_2d;
}

/**
 * Generates hash string from address.
 * Useful to create sets with address hashes from lists of housing addresses
 * and for address comparisons
 * @param {Address} addr
 * @returns {string}
 */
function hash_address(addr) {
  if ("x" in addr && "y" in addr) return addr.x + addr.y;
  if ("x" in addr) return String(addr.x);
  if ("y" in addr) return String(addr.y);
  throw new Error("Unhashable or unexpected address", addr);
}

// this checks if two housings have same dimensions, say 12x8 or just 10 in a row
function dimensions_same(dims1, dims2) {
  if (Object.keys(dims1).length !== Object.keys(dims2).length) return false;
  for (let i = 0; i < Object.keys(dims1).length; i++) {
    if (Object.keys(dims1)[i].length !== Object.keys(dims2)[i].length) return false;
  }
  return true;
}

function housing_dimensions_same(housing1, housing2) {
  return dimensions_same(
    housing1.housing_type.dimensions[0],
    housing2.housing_type.dimensions[0],
  );
}

function housing_addresses(housing) {
  let r = [];
  for (const dimension in housing.housing_type.dimensions[0]) {
    if (r.length === 0) {
      for (const val of housing.housing_type.dimensions[0][dimension]) {
        const z = {};
        z[dimension] = val;
        r.push(z);
      }
    } else {
      const r1 = [];
      for (const addr of r) {
        for (const val of housing.housing_type.dimensions[0][dimension]) {
          const z = {};
          z[dimension] = val;
          r1.push({ ...addr, ...z });
        }
      }
      r = r1;
    }
  }
  return r;
}
function format_scale(ss, scale_options) {
  const key = ss.sample.target_samples[0]?.target?.target_shop_order_positions[0]?.shop_order_position?.meta?.scale;
  const option = (scale_options || []).find((e) => e.key === key) || {
    title: "",
  };
  return option.title;
}
function format_purification(ss, purification_options) {
  const key = ss.sample.compound?.compound_substances[0]?.content_specs[0]?.purification;
  const option = (purification_options || []).find((e) => e.key === key) || {
    title: "",
  };
  return option.title;
}
const address_comparator = (target_address, compared_address_nested = false) => (item) => Object.keys(target_address).every(
  (dim_addr) => (compared_address_nested
    ? (target_address[dim_addr] === item.address[dim_addr])
    : (target_address[dim_addr] === item[dim_addr])),
);

const address_to_number = (address) => {
  const one_dim_coeff = 100;
  const two_dim_coeff = 1000;
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const has_y = "y" in address;
  const has_x = "x" in address;
  if (has_x && has_y) {
    return two_dim_coeff + Number.parseInt(address.x) * alphabet.length + alphabet.indexOf(address.y);
  }
  return one_dim_coeff + Number.parseInt(address?.x || address.y);
};
function get_ss_color(hs, ss, ordered_hs_cards) {
  const res = ordered_hs_cards?.in
    .find(({ housing_state: { id } }) => id === hs.id)
    .wells
    .find(address_comparator(ss.address)).style?.fill;

  return res;
}
/**
 * Function checks if the color is light or dark
 * (use case: if the bg is dark then the font-size should be white and such)
 * @param {String} color - hex code of color
 * @returns
 */
function check_brightness(color) {
  const { r, g, b } = hex_to_rgb(color);

  const hsp = Math.sqrt(
    0.299 * (r * r)
    + 0.587 * (g * g)
    + 0.114 * (b * b),
  );
  return hsp > 140 ? "light" : "dark";
}
function get_ss_font_color(hs, ss, ordered_hs_cards) {
  const bg_color = get_ss_color(hs, ss, ordered_hs_cards);
  if (!bg_color) return "";
  return ({
    light: "black",
    dark: "white",
  })[check_brightness(bg_color)];
}
function format_housing_name(hs) {
  return (hs.housing.name ?? hs.housing.housing_type.name)
    .split(" ")
    .map((word, ind, self) => ((self.length > 3 && !Number.isNaN(self.at(-1)) && ind !== self.length - 1)
      ? word[0].toUpperCase()
      : `${(!Number.isNaN(word) && " ") || ""}${word}`)).join("");
}
// remove addresses from minuend that are absent in subtrahend
function subtract_addresses(minuend, subtrahend) {
  const sub_hash = new Set();
  for (const addr of subtrahend) {
    sub_hash.add(hash_address(addr));
  }
  const r = [];
  for (const addr of minuend) {
    const hash = hash_address(addr);
    if (sub_hash.has(hash)) {
      continue;
    }
    r.push(addr);
  }
  return r;
}

// This function calls the 'fire' method on the specified konva-shape obj
// with custom event name and payload.
const fire_manually = (shapeObj, evt_name, payload, bubble = true) => shapeObj.fire(evt_name, payload, bubble);

// In combination with the previous function - ssed to bootstrap the higher-order semantically-named functions.
// refer to ./housing_layers.vue -> on_well_dragmove() function for examples.
const create_emitter = (...args) => fire_manually.bind(null, ...args);

/**
* helper function:
* returns a sorted array of housing states for a housing.
* Order is as they are obtained in operations
*/
function sort_hss_historically(housing_states) {
  let limit = housing_states.length;
  if (!limit) {
    return [];
  }
  const sorted = [];
  let origin_hs = housing_states[0];

  // find the historically first housing state for the housing
  for (; ;) {
    // zero incoming operations - this is the beginning if there was no origin operation
    if (!origin_hs.group_operation_in_id) {
      break;
    }
    const prev_group_operation_id = origin_hs.group_operation_in_id;
    const prev_hs = housing_states.find(
      (e) => e.group_operation_out_id
        && e.group_operation_out_id === prev_group_operation_id,
    );

    // no previous state - this is the beginning in normal case
    if (!prev_hs) break;
    origin_hs = prev_hs;
    limit--;
    if (limit <= 0) {
      // cyclic structure
      throw new Error(
        "Malformed housing states for housing ",
        housing_states[0] ? housing_states[0].housing_id : -1,
      );
    }
  }

  sorted.push(origin_hs);
  for (; ;) {
    // that's the end
    if (!sorted.at(-1)?.group_operation_out_id) {
      break;
    }
    const next_group_operation_id = sorted.at(-1)?.group_operation_out_id;
    const next_hs = housing_states.find(
      (e) => (e.group_operation_in_id ?? -1) === next_group_operation_id,
    );

    // that's the end too
    if (!next_hs) break;
    sorted.push(next_hs);
    if (sorted.length > housing_states.length) {
      throw new Error(
        "Malformed housing states for housing ",
        housing_states[0] ? housing_states[0].housing_id : -1,
      );
    }
  }
  if (sorted.length !== housing_states.length) {
    console.warn(
      "Length of housing_states after sorting is less than before sorting",
      housing_states[0] ? housing_states[0].housing_id : -1,
    );
  }
  return sorted;
}
export {
  rgb_to_hex,
  rgb_to_hsv,
  hsv_to_rgb,
  hsv_to_hex,
  hex_to_hsv,
  hex_to_rgb,
  check_brightness,
  translate_address,
  hash_address,
  format_scale,
  dimensions_same,
  housing_dimensions_same,
  housing_addresses,
  subtract_addresses,
  address_comparator,
  assign_addresses,
  fire_manually,
  create_emitter,
  format_purification,
  get_ss_color,
  get_ss_font_color,
  format_housing_name,
  sort_hss_historically,
  address_to_number,
};
