<template>
  <v-navigation-drawer
    v-if="capacities_loaded"
    v-model="is_open"
    touchless
    app
    mobile-breakpoint="sm"
    width="200"
    class="main-navigation"
  >
    <v-list
      v-if="is_open"
      dense
      class="pt-5 pb-5"
    >
      <v-list-item
        class="v-list-item--home"
        link
        :to="{ path: '/' }"
      >
        <v-list-item-icon>
          <v-icon color="secondary">
            mdi-home
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="v-list-item__title-root">
            Home
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <AppDrawerLeftNode
        v-for="grp in Object.keys(menu_groups)"
        :key="grp"
        :grp="grp"
        :group_obj="menu_groups[grp]"
        :sub_group="false"
      />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import AppDrawerLeftNode from "./app_drawer_left_node.vue";

export default {
  components: {
    AppDrawerLeftNode,
  },
  data() {
    return {
      is_open: true,
      menu_groups: {
        general: {
          title: "General",
          icon: "mdi-factory",
        },
        chemistry: {
          title: "Chemistry",
          icon: "mdi-flask",
        },
        oligos: {
          title: "Oligos",
          icon: "mdi-focus-field",
        },
        warehouse: {
          title: "Warehouse",
          icon: "mdi-warehouse",
        },
        config: {
          title: "Config",
          icon: "mdi-cog",
          children: {
            oligos_config: {
              icon: "mdi-dna",
              title: "Oligos",
            },
          },
        },
      },
      capacities_loaded: 0,
    };
  },
  watch: {
    is_open: {
      handler(val) {
        localStorage.setItem("global:menu", val);
      },
    },
    "$root.loading_status": {
      immediate: true,
      handler() {
        const CAPACITIES_LOADED = 2;
        this.capacities_loaded = this.$root.loading_status === CAPACITIES_LOADED;
      },
    },
  },
  created() {
    const open_temp = JSON.parse(localStorage.getItem("global:menu"));
    this.is_open = window.innerWidth <= 600 ? false : open_temp ?? this.is_open;
  },
  methods: {
    toggle() {
      this.is_open = !this.is_open;
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/styles/layouts/app_drawer_left.sass"
</style>
